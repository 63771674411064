.splashDark-unberry {
  background-color: #141414;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 128.2vh;
  flex-direction: column;
  background-image: url("./static/gameBg.svg");

  .instructions {
    margin-top: 30px;
    h5 {
      font-family: "Circular Std Book";
      font-style: normal;
      font-weight: normal;
      font-size: 27px;
      line-height: 20px;
      letter-spacing: -0.425px;
      text-align: center;
      color: #ffffff;
    }
    h6 {
      margin-top: 10px;
      font-family: "Circular Std Book";
      color: #cdcdcd;
      width: 380px;
      text-align: center;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
      /* or 156% */

      text-align: center;
      letter-spacing: -0.1px;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    background: #191a1c;
    border: 3px solid #48494a;
    box-sizing: border-box;

    /* Note: backdrop-filter has minimal browser support */

    border-radius: 24px;
    /* Note: backdrop-filter has minimal browser support */
    width: 277px;
    border-radius: 24px;
    padding: 15px;

    button {
      width: 293px;
      &::after {
        width: 293px;
      }
    }
    .game-info {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;

      h6 {
        font-family: Apercu;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #85919d;
      }
      h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 35.0658px;
        line-height: 44px;
        /* identical to box height */
        margin: 0px;
        text-align: center;
        letter-spacing: -0.109581px;

        color: #ffffff;
        font-family: Apercu;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 50px;
        span {
          font-size: 20px;
        }
      }
      .bar {
        width: 1px;
        height: 30px;
        background-color: white;
      }
    }
  }
  .questionOptions {
    box-sizing: border-box;
    border-radius: 4.61752px;
    width: 160px;
    height: 46px;

    position: relative;
    margin-top: 20px;
    margin-left: 8px;
    margin-right: 8px;
    background: #38a081;
    border: 2.30876px solid rgba(27, 89, 71, 0.6);
    &::after {
      content: "z";
      text-indent: -9999px;
      width: 160px;
      height: 46px;

      background: #2cc597;
      border: 2.30876px solid rgba(27, 89, 71, 0.6);
      box-sizing: border-box;
      border-radius: 4.61752px;
      position: absolute;
      left: -2px;
      top: -8px;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      margin-top: -16px;
      font-family: Apercu;
      font-style: normal;
      font-weight: bold;
      font-size: 18.4701px;
      line-height: 23px;
      text-align: center;
      letter-spacing: -0.057719px;
      width: 100%;
      color: #ffffff;
    }
    &.style-4 {
      margin-top: 40px;
      background: #4337ae;
      border: 2px solid #11578a;
      width: 350px;
      &::after {
        background: #665bc8;
        border: 2px solid #11578a;
        width: 350px;
      }
    }
  }
}
