.retry-text {
  font-family: "VolteRounded-Bold";
  font-size: 30px;
  color: #58595a;
}

.left__content-controls {
  @media (max-width: 500px) {
    position: fixed;
    bottom: 0px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    background: rgba(220, 232, 255, 0.1);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 120px;
  }
}

.retry-img {
  margin-right: 8px;
}
.control-btn {
  z-index: 1;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  img {
    object-fit: contain;
  }
  @media (max-width: 500px) {
    margin: 0px;
    img {
      margin: 0px;
    }
  }
}
.next-btn {
  position: relative;
  img {
    position: absolute;
    top: 0px;
    left: 0px;
  }
}

@keyframes backOutRight {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  20% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }
}
