.react-responsive-modal-closeButton {
  fill: #fff;
}
.component-header {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  min-height: 65px;
  flex-wrap: nowrap;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0px 100px 0 100px;
  background-color: #151515;
  .display-mobile {
    display: none;
  }
  @media (max-width: 500px) {
    padding: 20px 20px;
    .display-desktop,
    .chapter {
      display: none;
    }
    .display-mobile {
      display: block;
    }
  }
  button {
    background: #2e2e2e;
    border: 2px solid #505050;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 1000px;
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      // background: #3a4657;
      // border: 2px solid #777f8d;
      border-width: 2px;
      width: 40px;
      height: 40px;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    span {
      cursor: pointer;
    }
  }
  .chapter {
    font-family: Apercu;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    /* identical to box height */

    letter-spacing: 1px;

    color: #ffffff;
    background: #2e2e2e;
    border: 2px solid #505050;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 5px 15px;
  }
  .left-header {
    .header-title {
      padding-top: 8px;
      font-size: 30px;
      color: #fff;
      font-family: "SfFedora";
    }
  }
  .center-header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    .timer {
      font-weight: 700;
      font-size: 30px;
    }
    display: flex;
    align-items: center;
  }
  .right-header {
    // width: 40%;
    // display: flex;
    // justify-content: flex-end;
    margin-left: 0px;
    @media (max-width: 767px) {
      margin-left: 26px;
    }
  }
}
.menu-img {
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    scale: 1.1;
  }
  transition: 0.35s all;
}
