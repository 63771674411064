.header-modal-component-modal {
  border-radius: 10px;
  animation: none !important;
}

.header-modal-component-overlay {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(12, 15, 22, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
}

.puzzle__list {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  min-width: 600px;

  &-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 87px;
    border-radius: 10px;

    box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.5);
    background-color: #58595a;
    font-family: "VolteRounded-Bold";
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff79;
  }
  &-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }
  &-tab {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px 0 20px 0; 
    &-item {
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &-number{
    font-size: 32px;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-moves{
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-records{
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      @media (max-width: 767px){
        width: 20px;
        height: 20px;
      }
    }
  }
  &-item {
    display: flex;
    width: 200px;
    height: 200px;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #444;

    &:hover{
      transform: scale(1.08);
    }
    transition: all 0.4s ease-in;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #fff;
    margin: 12px;
  }
}

.disabled-item {
  opacity: 0.6;
  &:hover{
    transform: none;
  }
}
