.gameBg {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e0e0e;
}

.level-detail {
  display: none;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8b8b8;
  padding-bottom: 60px;
  font-family: "VolteRounded-Bold";
  font-size: 50px;
  z-index: 1;
  @media (max-width: 767px) {
    display: none;
    padding-bottom: 0px;
  }
}
.level-detail-right {
  display: none;
  @extend .level-detail;
  display: none;
  @media (max-width: 767px) {
    display: flex;
    display: none;
    padding-top: 20px;
    // padding-bottom: 70px;
  }
}
