.score-modal {
  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    background: rgba(220, 232, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    backdrop-filter: blur(11px);
    /* Note: backdrop-filter has minimal browser support */
    width: 322px;
    border-radius: 24px;
    padding: 20px 14px;

    button {
      width: 293px;
      &::after {
        width: 293px;
      }
    }
    .modal-content {
      width: 1000px;
      height: 1000px;
      background: rgba(220, 232, 255, 0.1);
      border: 2px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      backdrop-filter: blur(22px);
      /* Note: backdrop-filter has minimal browser support */
      display: none;
      border-radius: 24px;
      position: relative;
    }
    .puzzle-number {
    }
  }
}
.modal-component-container {
  width: 450px;
  height: auto;
  padding-top: 35px;
  padding-bottom: 35px;
  background: rgba(220, 232, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  backdrop-filter: blur(22px);
  /* Note: backdrop-filter has minimal browser support */

  &.exit-level {
    width: unset;
    max-width: 540px;
    min-width: 360px;
  }

  .exit-level-container {
    padding: 50px;

    @media (max-width: 500px) {
      padding: 50px 40px;
    }
    @media (max-width: 400px) {
      padding: 50px 2cm;
    }
  }

  border-radius: 24px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .puzzle-number {
    color: #fff;
    position: absolute;
    top: 115px;
    left: 50%;
    font-size: 28px;
    transform: translateX(-50%);
    margin-left: 1px;
  }
  .modal-content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h6 {
      font-family: Apercu;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      letter-spacing: -0.11201px;
      margin-top: 10px;
      color: #ffffff;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    background: rgba(220, 232, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    backdrop-filter: blur(11px);
    /* Note: backdrop-filter has minimal browser support */
    width: 322px;
    border-radius: 24px;
    padding: 20px 14px;

    button {
      width: 293px;
      &::after {
        width: 293px;
      }
    }
    .game-info {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 15px;
        /* identical to box height */

        text-align: center;
        letter-spacing: -0.038115px;

        color: #8c96a4;
        margin: 0px;
      }
      h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 35.0658px;
        line-height: 44px;
        /* identical to box height */
        margin: 0px;
        text-align: center;
        letter-spacing: -0.109581px;

        color: #ffffff;
      }
      .bar {
        width: 1px;
        height: 30px;
        background-color: white;
      }
    }
  }
  .questionOptions {
    box-sizing: border-box;
    border-radius: 4.61752px;
    width: 400px;
    height: 46px;

    position: relative;
    margin-top: 20px;
    margin-left: 8px;
    margin-right: 8px;
    background: #38a081;
    margin-top: 40px;
    border: 2.30876px solid rgba(27, 89, 71, 0.6);
    &::after {
      content: "z";
      text-indent: -9999px;
      width: 400px;
      height: 46px;

      background: #2cc597;
      border: 2.30876px solid rgba(27, 89, 71, 0.6);
      box-sizing: border-box;
      border-radius: 4.61752px;
      position: absolute;
      left: -2px;
      top: -8px;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      margin-top: -16px;
      font-family: Apercu;
      font-style: normal;
      font-weight: bold;
      font-size: 18.4701px;
      line-height: 23px;
      text-align: center;
      letter-spacing: -0.057719px;
      width: 100%;
      color: #ffffff;
    }
    &.style-4 {
      background: #4337ae;
      border: 2px solid #11578a;
      &::after {
        background: #665bc8;
        border: 2px solid #11578a;
      }
    }
  }
  h5 {
    font-family: Apercu;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.151214px;

    color: #ffffff;
  }
  h6 {
    font-family: Apercu;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.0906314px;

    color: #868989;
  }
}
