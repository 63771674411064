.timer-bar {
  .level {
    display: none;
    font-family: Apercu;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;

    color: #3e8afe;
  }
  @media (max-width: 500px) {
    .level {
      display: block;
    }
  }
  .timer {
    background: rgba(220, 232, 255, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 36px;
    width: 330px;
    height: 28px;
    position: relative;
    margin-left: -50px;

    span {
      font-size: 15px;
      color: white;
      font-weight: bold;
      position: absolute;
      left: 45%;
      transform: translateX(-50%);
      top: 50%;
      transform: translateY(-50%);
    }
    .timer-fill {
      position: absolute;
      height: 100%;
      border-radius: 36px;
      background-color: #e5325f;
    }

    @media (max-width: 500px) {
      width: auto;
      background: none;
      border: none;
      .timer-fill {
        display: none;
      }
      span {
        font-size: 22px;
      }
      .level {
        display: block;
      }
    }
  }
}
