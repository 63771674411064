body {
  font-family: "VolteRounded-Semibold";
  overflow: auto;
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "NeueHaasUnicaPro-Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image: radial-gradient(circle at 50% 0, #cfe0f8, #b8cae7 78%);
  // @media (max-width: 767px) {
  //   padding: 0;
  //   margin: 0;
  //   border: none;
  //   background-image: radial-gradient(circle at 50% 0, #ffffff, #ffffff 78%);
  // }
  // cursor: URL('../berrySmiling.svg'), auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --top-color: #7033d3;
  --main-color: #ff505b;
  --back-color: #fff;
  --light-color: #000;
  --dark-color: #aaa;
  --btn: #02d7bf;
  zoom: 78%;
}

.base-container {
  display: flex;
  flex-direction: column;
  background-color: #f3f4fd;
}
.list-holder {
  width: 350px;
  border: 3px inset;
  margin: auto;
}
.list-holder table {
  width: 100%;
  list-style-type: none;
  border-collapse: collapse;
}
.list-holder tr {
  border: 2px solid var(--dark-color);
}
.list-holder tr:hover {
  color: var(--light-color);
  background-color: var(--back-color);
}
.list-holder td {
  padding: 3px;
}

.button-small {
  width: 58px;
  height: 58px;
  border-radius: 10px;
  color: #fff;
  box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.5);
  background-color: #58595a;
  border: none;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-right: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
}
.bigButton {
  font-size: 30px;
}
.medButton {
  background-color: var(--btn);
}
.smallButton {
  font-size: 15px;
}
.button-small:disabled {
  color: var(--top-color);
  filter: opacity(0.6);
  transition: all 0.6s;
}
.button-small:enabled:active:hover {
  filter: opacity(0.8);
  transition: all 0.6s;
}

.inset-box {
}
.move-info-box {
  /* HACK */
  height: 52px;
  width: 150px;
  padding: 3px;
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  line-height: 1.5em;
}
.block-hor {
  background-image: URL("../block-horzontal.svg");
}

.block {
  background-image: URL("../block.svg");
  // background-image: URL("../block-horzontal.svg");
  background-repeat: repeat;
  background-position: left;
  cursor: grab;
  // color: var(--top-color);
  // background-color: var(--top-color);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 1px;
  border-width: 4px;
  border-style: outset;
  border-radius: 4px;

  border-radius: 10px;
  box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.5);
  // border: solid 1px #ffffff;
  border: none;
  // border-color: var(--top-color);
  z-index: 10;
}

.main-block {
  cursor: grab;
  border: none;
  // color: var(--main-color);
  // background-color: var(--main-color);
  // border-color: var(--main-color);
  background-image: URL("../main-block.svg");
  background-repeat: repeat;
  background-position: left;
}

.block-shadow {
  box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.5);
  border: solid 1px #ffffff;
  z-index: 5;
}

.overlay {
  z-index: -20;
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  display: hidden;
  opacity: 0;
  transition: opacity 500ms 500ms;
}
.win-overlay {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 20;
  opacity: 1;
}

.board-content {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 50px 57px 50px 57px;
  border-radius: 10px;
  box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.09);
  background-color: none;
  @media (max-width: 767px) {
    transform: scale(0.75) !important;
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
  }
}

.board-component {
  background-image: URL("../wall_border.svg");
  width: 576px;
  height: 576px;
  object-fit: contain;
  padding: 74px;
  background-repeat: no-repeat;
  object-fit: contain;
  padding: 74px;
  background-repeat: no-repeat;
  background-size: 576px;
  background-position: 69px 55px;
}

.timer-modal-content {
  display: flex;
  justify-content: center;
  padding-top: 57px;
  padding-bottom: 45px;
  .btn {
    width: 160px;
  }
}

.btn-outline {
  background-color: transparent;
  border: none;
  color: var(--btn);
  font-size: 35px;
  min-width: 296px;
  height: 84px;
  border-radius: 10px;
  padding: 13px 20px 23px;
  text-align: center;
  display: inline-block;
  border: none;
  font-size: 30.2px;
  color: var(--btn);
  font-family: "VolteRounded-Bold";
  text-transform: uppercase;
}

.main__container {
  display: flex;
  flex-direction: row;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}
.main__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 27px;
  @media (max-width: 767px) {
    box-shadow: none;
    border: none;
    padding: 0;
    margin: 0;
  }
}

.right__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    object-fit: contain;
    margin-bottom: 15px;
    cursor: pointer;
    // &:hover {
    //     scale: 1.1;
    // }
    // transition: 0.35s all;
  }
}

.left__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      padding: 20px;
      img {
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
      flex-direction: row;
    }
  }

  img {
    object-fit: contain;
    margin-bottom: 15px;
    cursor: pointer;
    // &:hover {
    //     scale: 1.1;
    // }
    // transition: 0.35s all;
  }
}
