@font-face {
  font-family: "VolteRounded-Bold";
  src: url("../VolteRounded-Bold.otf") format("truetype");
}
@font-face {
  font-family: "VolteRounded-Light";
  src: url("../VolteRounded-Light.otf") format("truetype");
}
@font-face {
  font-family: "VolteRounded-Medium";
  src: url("../VolteRounded-Medium.otf") format("truetype");
}
@font-face {
  font-family: "VolteRounded-Regular";
  src: url("../VolteRounded-Regular.otf") format("truetype");
}
@font-face {
  font-family: "VolteRounded-Semibold";
  src: url("../VolteRounded-Semibold.otf") format("truetype");
}

@font-face {
  font-family: "SfFedora";
  src: url("../SfFedora-Emxr.ttf") format("truetype");
}

@font-face {
  font-family: "Apercu";
  src: local("Rajdhani"),
    url(../../fonts/Apercu/Apercu_Regular.otf) format("truetype");
}
@font-face {
  font-family: "Circular Std Book";
  src: local("Rajdhani"),
    url(../../fonts/CircularStd/CircularStd-Book.ttf) format("truetype");
}

* {
  font-family: Apercu;
}

button {
  cursor: pointer;
}

//global container
.container {
  max-width: 1140px;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
}

// .v-bold{
//     font-family: "v-bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
//       "Open Sans", "Helvetica Neue", sans-serif;
// }

// .v-regular{
//   font-family: "v-regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
//     "Open Sans", "Helvetica Neue", sans-serif;
// }

// .v-light{
//   font-family: "v-light", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
//     "Open Sans", "Helvetica Neue", sans-serif;
// }

// .v-medium{
//   font-family: "v-medium", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
//     "Open Sans", "Helvetica Neue", sans-serif;
// }

// .v-semibold{
//   font-family: "v-semibold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
//     "Open Sans", "Helvetica Neue", sans-serif;
// }

html {
  .prevent-scroll {
    overflow: hidden !important;
  }
  .no-scroll-checkout {
    overflow: hidden !important;
  }
  .scroll {
    overflow: hidden !important;
  }
}
body {
  font-size: 16px;
  font-family: "ProximaNova", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.mw {
  font-family: "Merriweather", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.light {
  // font-weight: 300 !important;
}

.regular {
  font-family: "ProximaNova-regular";
  // font-weight: 400 !important;
}

.semibold {
  font-family: "ProximaNova-semibold";
  // font-weight: 600;
}
.medium {
  font-family: "ProximaNova-medium";
  // font-weight: 500 !important;
}
.prevent-scroll {
  overflow: hidden;
}

.bold {
  font-family: "ProximaNova-bold";
  // font-weight: 700;
}
.black {
  font-weight: 900;
}
.mw.bold {
  font-family: "Merriweather-bold";
}
.mw.light {
  // font-weight: 300;
  font-family: "Merriweather-light";
}
.mw.regular {
  font-family: "Merriweather-regular";
}
h1,
h2,
h3,
h4,
h5,
p,
a,
strong {
  font-weight: initial !important;
}
// .pn {
//   font-family: "ProximaNova", -apple-system, BlinkMacSystemFont, "Segoe UI",
//     Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
// }
// .................... common flex classes....................
// fonts

// display
.flex {
  display: flex;
}

//direction
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-1 {
  flex: 1 1 0%;
}

//wrap
.flex-wrap {
  flex-wrap: wrap;
}

//align-items
.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

// justify-content
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}

.h-full {
  height: 100%;
}
// --------- font-weights ----------

// --------------------
// ---- text--center ------
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// font size
.font-10 {
  font-size: 10px;
}
.text-xs {
  font-size: 12px;
}
.text-18 {
  font-size: 18px !important;
}
.text-13 {
  font-size: 13px;
}
.text-14 {
  font-size: 14px;
}
// todo: revert it if yours team want to change font sizes: changed from 18 to 16 throughout app
.text-sm {
  font-size: 16px;
}

.text-base {
  font-size: 16px;
}
.text-18 {
  font-size: 18px;
}
.text-20 {
  font-size: 20px;
}
.text-md {
  font-size: 22px;
}
// todo: revert it if yours team want to change font sizes: changed from 24 to 26 throught app
.text-lg {
  font-size: 26px;
}
.text-26 {
  font-size: 26px;
}
.text-28 {
  font-size: 28px;
}
.text-24 {
  font-size: 24px;
}
.text-xl {
  font-size: 30px;
}
.text-2xl {
  font-size: 32px;
}
.text-3xl {
  font-size: 36px;
}
.text-4xl {
  font-size: 40px;
}
.text-5xl {
  font-size: 45px;
}
.text-6xl {
  font-size: 48px;
}
.jumbotron {
  font-size: 60px;
}
.text-42 {
  font-size: 42px;
}

// font weight
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  // font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}

// text colors
.text-white {
  color: #fff;
}

.text-black {
  color: #000;
}
.theme-color {
  color: #373873;
}
.secondary-color {
  color: #454684;
}
.text-grey {
  color: #777777;
}
.mr-5 {
  margin-right: 5px;
}
.no-shadow {
  box-shadow: none !important;
}

// text transform
.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}
.scroll {
  overflow: hidden !important;
}

.capitalize {
  text-transform: capitalize;
}

// position
.relative {
  position: relative;
}
.line-normal {
  line-height: 1.33;
}
.line-45 {
  line-height: 1.45;
}
.line-56 {
  line-height: 1.56;
}
.line-22 {
  line-height: 1.22;
}
.outline-none {
  outline: none;
}
.text-end {
  text-align: end;
}
.line-21 {
  line-height: 21px;
}
.no-scroll {
  overflow: hidden !important;
}
.heading-primary-xl {
  font-size: 32px;
  font-weight: bold;
  color: #373873;
  font-family: "Merriweather", sans-serif;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    font-size: 26px !important;
    line-height: 1.42;
  }
}

.zOne {
  z-index: 1;
}

.heading-primary-l {
  font-size: 26px;
  font-weight: bold;
  color: #373873;
  line-height: 1.3;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    font-size: 16px !important;
    line-height: 1.5;
  }
}

.heading-primary-md {
  font-size: 22px;
  font-weight: normal;
  color: #373873;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    font-size: 18px !important;
    line-height: 1.64;
  }
}

.heading-primary-sm {
  font-size: 16px;
  font-weight: normal;
  color: #373873;
  line-height: 1.3;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    font-size: 14px !important;
  }
}

.block {
  display: block;
}

.none {
  display: none;
}

.inline-block {
  display: block;
}

.hidden {
  visibility: hidden;
}

.fixed {
  position: fixed;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.opacity-5 {
  opacity: 0.5;
}
.cursor-pointer {
  cursor: pointer;
}

button {
  cursor: pointer;
}

.desktop-only {
  @media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
    display: none !important;
  }
}

.mobile-only {
  @media only screen and (min-device-width: 1025px) {
    display: none !important;
  }
}

a:-webkit-any-link {
  color: unset;
  text-decoration: none;
}

.rotate-180 {
  transform: rotate(180deg);
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.underline {
  text-decoration: underline;
}
.mt-5 {
  margin-top: 5px;
}
// generates text-12 class for font-sizes
@for $i from 10 through 40 {
  .text-#{$i} {
    font-size: 1px * $i;
  }
}

// font-300 for font-weight: 300
@for $i from 1 through 9 {
  .font-#{$i*100} {
    font-weight: 100 * $i;
  }
}

//padding-bottom
@for $i from 1 through 30 {
  .pb-#{$i*2} {
    padding-bottom: 2px * $i;
  }
}

//padding-top
@for $i from 1 through 30 {
  .pt-#{$i*2} {
    padding-top: 2px * $i;
  }
}

//padding-left
@for $i from 1 through 30 {
  .pl-#{$i*2} {
    padding-left: 2px * $i;
  }
}

//padding-right
@for $i from 1 through 30 {
  .pr-#{$i*2} {
    padding-right: 2px * $i;
  }
}

// margin-bottom
@for $i from 1 through 30 {
  .mb-#{$i*2} {
    margin-bottom: 2px * $i;
  }
}

//margin-left
@for $i from 1 through 20 {
  .ml-#{$i*2} {
    margin-left: 2px * $i;
  }
}

// margin-right
@for $i from 1 through 20 {
  .mr-#{$i*2} {
    margin-right: 2px * $i;
  }
}
.nav-header-title {
  font-size: 16px;
  display: block;
  margin-left: 58px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 60px;
  padding: 10px 22px 9px 22px;
  // border: 1px solid transparent;
  text-align: center;
  color: #373873;
  &:nth-child(1) {
    margin-left: 0;
    white-space: nowrap;
  }

  &:hover {
    // width: 165px;
    // border-color: #fff;
    // border-color: var(--hoveredClr);
    transition: all 0.3s;
    text-decoration: underline;
  }
}

// react-transitions
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.fadeInAnimate {
  animation: fadeIn 0.35s ease-in-out forwards;
}
.pac-container {
  // width: 396px !important;
  // left: 798px !important;
  // top: 547px !important;
  border-radius: 20px !important;
  padding: 0 10px 10px !important;
}
.pac-item {
  padding: 10px !important;
  font-size: 16px !important;
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 16px !important;
  }
}
.pac-item-query {
  font-family: "ProximaNova-bold";
  color: #373873 !important;
  font-size: 16px !important;
  padding-right: 10px !important;
  @media (min-width: 320px) and (max-width: 1024px) {
    font-size: 16px !important;
  }
}
.no-scroll-checkout {
  overflow: hidden !important;
}

::-webkit-scrollbar {
  display: none;
}

p {
  font-family: "VolteRounded-Bold";
}

.btn {
  cursor: pointer;
  min-width: 296px;
  height: 84px;
  border-radius: 10px;
  box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.5);
  background-color: #58595a;
  padding: 13px 20px 23px;
  text-align: center;
  display: inline-block;
  border: none;
  font-size: 30.2px;
  color: #ffffff;
  font-family: "VolteRounded-Bold";
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    //   box-shadow: none;
  }
  &:focus {
    outline: none;
  }
  img {
    vertical-align: middle;
    margin: -4px 0 0 10px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    height: 60px;
    min-width: 190px;
    padding: 10px 10px 13px;
    img {
      width: 14px;
    }
  }
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}
